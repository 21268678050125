/* Various generic utility functions */

class CommonUtil {
  /**
   * Returns `true` IFF `val` is `t`, `true`, `yes`, `1`, etc.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static truthyString(val: any): boolean {
    return /^((T(rue)?)|(Y(es)?)|([1-9]\d*))$/i.test(`${val}`);
  }

  /**
   * Generates a new object that combines the top-level attributes of the given
   * list such that an attribute defined in the N+1th argument takes precedence
   * over an attribute with the same name defined in the Nth argument.
   *
   * E.g.:
   *  - `merge({a:1}, {a:2,b:2})` yields `{a:2,b2}`
   *  - `merge({a:2,b:2}, {a:1})` yields `{a:1,b2}`
   *
   * `undefined` values are ignored; `null` values are not; e.g.:
   *  - `merge({a:1,b:2}, {a:null,b:undefined})` yields `{a:null,b:2}`
   */
  static merge(...objs: object[]): object {
    let result = {};
    for (const obj of objs) {
      result = Object.assign(result, this.stripUndefinedValues(obj ?? {}));
    }
    return result;
  }

  /**
   * Returns a shallow copy of `obj` with any top-level,
   * `undefined`-valued attributes removed.
   */
  static stripUndefinedValues(obj: object): object {
    return this.filterByValue(obj, this._isntUndefined);
  }

  /**
   * Returns a shallow copy of `obj` with any top-level,
   * `null` or `undefined`-valued attributes removed.
   */
  static stripNullAndUndefinedValues(obj: object): object {
    return this.filterByValue(obj, this._isntNullOrUndefined);
  }

  /**
   * Returns a shallow copy of `obj` with top-level attributes not
   * matching the given predicate removed.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static filterByValue(obj: object, predicate: (value: any) => boolean): object {
    const result: object = {};
    for (const key of Object.keys(obj)) {
      const value = obj[key as keyof typeof obj];
      if (predicate(value)) {
        result[key as keyof typeof result] = value;
      }
    }
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static _isntUndefined(value: any): boolean {
    return value !== undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static _isntNullOrUndefined(value: any): boolean {
    return value !== null && value !== undefined;
  }

  //-------------------------------------------------------------------------//

  /** Pretty-print JSON to a String. */
  static jsonpp(obj: object): string {
    return JSON.stringify(obj, null, 2);
  }

  /** Pretty-print JSON to a String, with a prefix. */
  static jsonppp(prefix: string, obj: object): string {
    return prefix + this.jsonpp(obj);
  }
}

export default CommonUtil;
