/*
 * Adds some convenience methods to globalThis for easy access from the
 * browser's JS console.
 *
 * >~~> THESE SHOULD NOT USED IN SOURCE CODE <~~<
 *
 * This is intended to support dev/debugging activities in the browser.
 */
import WebMediaUtil from "@/utils/WebMediaUtil";
import CommonUtil from "@/utils/CommonUtil";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __CLXG__: any;
  }
}

class Globals {
  static init(): void {
    // ensure the container is initialized
    window.__CLXG__ = window.__CLXG__ || {};
    // populate some handy utility types
    this.addGlobal("WebMediaUtil", WebMediaUtil);
    this.addGlobal("CommonUtil", CommonUtil);
  }

  /**
   * Adds a property to our global-scope container.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static addGlobal(key: string, value: any): void {
    window.__CLXG__[key] = value;
  }
}

export default Globals;
