<template lang="pug">
.feedback-container
  .feedback-container-row
    PublisherInternetStability(v-if="streamEnabled")
    ErrorDisplay
    Toast
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import PublisherInternetStability from "../Vonage/PublisherInternetStability.vue";
import { Toast } from "@cruciallearning/puddle/toast";
import ErrorDisplay from "../Error/ErrorDisplay.vue";
@Component({
  components: {
    PublisherInternetStability,
    Toast,
    ErrorDisplay,
  },
  computed: {
    ...mapGetters("EventModule", ["streamEnabled"]),
  },
})
export default class Feedback extends Vue {}
</script>
<style scoped lang="scss">
.feedback-container {
  z-index: 10;
  height: fit-content;
  margin: 8px 8px 0px 0px;
  position: absolute;
  right: 0;
}
.feedback-container-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
